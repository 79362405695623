<template>
  <div id="menu">
    <div class="menu_shadow" v-show="matches && onOpen" @click="matchesClose"></div>
    <div class="menu_wrapper" :style="menu_wrapperStyle">
      <div class="menu_logo" v-if="(!isCollapse || matches) && onOpen">
        <router-link to="/home" class="logo">
          <img alt class="logoImg" />
          <p class="product">Client Portal</p>
        </router-link>
      </div>
      <div class="menu_logo_small" v-else>
        <router-link to="/home" class="logo">
          <img alt class="logoImg" />
        </router-link>
      </div>
      <el-scrollbar style="height: 100%">
        <el-collapse-transition>
          <el-menu
            class="el-menu-vertical-demo"
            background-color="#2e3880"
            text-color="rgb(255, 255, 255)"
            active-text-color="#41ba92"
            :unique-opened="true"
            :default-active="$route.path"
            :collapse="isCollapse && !matches"
            data-testid="menu"
          >
            <div v-for="(menu, index) in filteredMenus" v-bind:key="index" class="divider">
              <el-menu-item
                :index="checkHomeUrl(menu.path)"
                v-if="checkRegister(menu)"
                :data-testid="menu.name"
                @click="clickMenu(menu)"
              >
                <img
                  :src="menu.icon"
                  class="icon"
                  :class="{ icon_small: (isCollapse || !matches) && !onOpen }"
                  alt=""
                />
                <span slot="title">{{ $t(menu.name) }}</span>
              </el-menu-item>
              <el-submenu :index="menu.path" v-if="menu.children" :data-testid="menu.name">
                <template slot="title">
                  <img
                    :src="menu.icon"
                    class="icon"
                    :class="{ icon_small: (isCollapse || !matches) && !onOpen }"
                    alt=""
                  />
                  <span slot="title">{{ $t(menu.name) }}</span>
                </template>
                <template v-for="(children, index) in menu.children">
                  <el-menu-item
                    v-bind:key="index"
                    :index="children.path"
                    v-if="isOptional(children)"
                    :data-testid="children.name"
                    @click="clickMenu(children)"
                  >
                    <span>{{ $t(children.name) }}</span>
                  </el-menu-item>
                </template>
              </el-submenu>
            </div>
            <a
              v-if="userTypeEnum === 'CLIENT' && (!isCollapse || matches)"
              class="switch_to"
              @click.stop.prevent="getDepositBlacklist()"
              data-testid="switch_to_deposit_funds"
            >
              {{ $t('tradingTools.condition.depositNow') }}
            </a>
            <a
              v-if="userTypeEnum == 'IB' && (!isCollapse || matches)"
              class="switch_to"
              @click.stop.prevent="$redirect.redirectToIbPortal()"
              data-testid="switch_to"
            >
              {{ $t('common.button.goToIB') }}
            </a>
          </el-menu>
        </el-collapse-transition>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import openAccountMixin from '@/mixins/openAccount';
import blackList from '@/mixins/blackList';

export default {
  name: 'vMenu',
  props: {
    isCollapse: Boolean,
    matches: Boolean,
    onOpen: Boolean
  },
  data() {
    return {
      bodyDirection: null,
      promotionRafEligibility: false
    };
  },
  mixins: [openAccountMixin, blackList],
  methods: {
    matchesClose() {
      this.$emit('matchesClose');
    },
    checkMatchesClose() {
      const vm = this;
      this.$nextTick(function () {
        if (vm.matches) vm.matchesClose();
      });
    },
    clickMenu(children) {
      if (!children.hasOwnProperty('checkBlacklist')) {
        this.checkMatchesClose();
        this.$router.push({ path: children.path });
      } else {
        if (children.path === '/depositFunds') this.getDepositBlacklist();
        else this.getWithdrawalBlacklist();
      }
    },
    isOptional(children) {
      if (!children.hasOwnProperty('optional')) return children;
      else {
        if (this.openAdditionAccountEnabled) return children;
      }
    },
    checkRegister(menu) {
      return (menu.path != '/register' || this.registerStep < 6) && !menu.children;
    },
    checkHomeUrl(path) {
      return path == '/home' && this.registerStep == 1 && this.isDemo ? '/homeDemo' : path;
    },
    checkCampaignEligibility() {
      this.$store.dispatch('promotion/actionGetEligibleCampaigns');
    }
  },
  mounted() {
    this.checkCampaignEligibility();
    this.$nextTick(() => {
      this.bodyDirection = document.body.dir;
    });
  },
  watch: {
    $route(value) {
      if (value.path === '/home') {
        this.checkCampaignEligibility();
      }
    }
  },
  computed: {
    filteredMenus() {
      return this.$config.menu;
    },
    registerStep() {
      return this.$store.state.common.registerStep;
    },
    isDemo() {
      return this.$store.state.common.isDemo;
    },
    userTypeEnum() {
      return this.$store.state.common.userTypeEnum;
    },
    menu_wrapperStyle() {
      const menuWidth = { width: this.matches == false && this.isCollapse ? '64px' : '300px' };
      const direction = this.bodyDirection === 'rtl' ? { right: this.menuPosition } : { left: this.menuPosition };
      return { ...direction, ...menuWidth };
    },
    menuPosition() {
      return this.matches && !this.onOpen ? '-300px' : '0px';
    },
    campaignEligibility() {
      return this.$store.state.promotion.eligibleCampaigns;
    },
    campaignParticipation() {
      return this.$store.state.promotion.participatedCampaigns;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/layout/menu.scss';
</style>
