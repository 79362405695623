<template>
  <el-container :key="reRender" class="wrapper">
    <el-aside v-show="showMenu" :width="!matches ? center : ''">
      <slideBar
        :isCollapse.sync="isCollapse"
        :matches="matches"
        :onOpen.sync="onOpen"
        @matchesClose="matchesClose"
      ></slideBar>
    </el-aside>
    <el-container class="is-vertical">
      <vHeader :matches="matches" @openMenu="openMenu"></vHeader>
      <el-main id="elMain">
        <transition>
          <router-view></router-view>
        </transition>
        <vFooter></vFooter>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import vHeader from '@/components/Header';
import slideBar from '@/components/Menu';
import vFooter from '@/components/Footer';
import { apiSetlanguage } from '@/resource';

export default {
  name: 'layout',
  data() {
    return {
      reRender: 0,
      center: '300px',
      matches: false,
      showMenu: false,
      isCollapse: false,
      onOpen: true
    };
  },
  components: { vHeader, slideBar, vFooter },
  computed: {
    lang() {
      return this.$store.state.common.lang;
    }
  },
  watch: {
    lang: {
      immediate: true,
      handler(val) {
        this.$i18n.locale = val;
        if (this.$store.state.common.loginStatus) this.setUserLanguagePreference(val);
        this.reRender++;
      }
    },
    reRender(reRender) {
      if (this.$route.meta.restrictReload) this.$router.push('/home');
    },
    onOpen(bool) {
      this.isCollapse = !bool;
    },
    isCollapse(bool) {
      if (!bool && this.onOpen) this.center = '300px';
      else if (!this.matches) this.center = '64px';
      else this.center = '0';
    },
    matches(bool) {
      if (bool) {
        this.onOpen = false;
        this.center = '0';
        this.isCollapse = false;
      } else {
        this.onOpen = true;
        this.center = '300px';
      }
    },
    $route: {
      handler: function (route) {
        this.showMenu = route.meta.allowAnonymous ? false : true;

        this.$nextTick(() => {
          const elMain = document.getElementById('elMain');
          elMain.scrollTop = route.path.indexOf('deposit/') != -1 ? 370 : 0;
        });
      },
      immediate: true
    }
  },
  mounted() {
    const vm = this;
    this.matches = window.matchMedia('(max-width: 1024px)').matches;
    $(window).resize(function () {
      vm.matches = window.matchMedia('(max-width: 1024px)').matches;
    });
  },
  methods: {
    async setUserLanguagePreference(lang) {
      await apiSetlanguage({ language: lang });
      this.$store.dispatch('regulator/actionGetInformation');
    },
    matchesClose() {
      this.isCollapse = false;
      this.onOpen = !this.onOpen;
    },
    openMenu() {
      this.isCollapse = !this.isCollapse;
      this.onOpen = !this.onOpen;
    }
  }
};
</script>

<style lang="scss">
@import '@/assets/css/app.scss';
.v-leave {
  opacity: 1;
}
.v-leave-active {
  transition: opacity 0.5s;
}
.v-leave-to {
  opacity: 0;
}
.v-enter {
  opacity: 0;
}
.v-enter-active {
  transition: opacity 0.2s;
}
.v-enter-to {
  opacity: 1;
}

@import '../../assets/GTWalsheimPro/stylesheet.css';
</style>
