import { apiWithdrawalBlacklist } from '@/resource'
import { apiDepositBlacklist } from '@/resource'

export default {
  methods: {
    getDepositBlacklist() {
      apiDepositBlacklist().then(resp => {
        resp.data.data
          ? this.$router.push({ path: '/depositFunds' })
          : this.$message({
              message: this.$t('responseMsg.568'),
              type: 'error',
            })
      })
    },
    getWithdrawalBlacklist() {
      apiWithdrawalBlacklist().then(resp => {
        resp.data.data
          ? this.$router.push({ path: '/withdrawFunds' })
          : this.$message({
              message: this.$t('withdraw.default.blackList', { supportEmail: this.GLOBAL_WITHDRAWAL_EMAIL }),
              type: 'error',
            })
      })
    },
  },
}
